<template>
  <div class="detailsMainBlock">
    <div class="patientInfoBlock">
      <div class="patientDetails">
        <el-row :gutter="30">
          <el-col :xl="6" :lg="7" :md="8" :sm="12" :xs="12">
            <div
              class="input-value"
              v-if="
                this.getCreateAccessionDetails &&
                this.getCreateAccessionDetails.accession_id
              "
            >
              <h4 class="name">ACCESSION ID</h4>
              <p class="specialValue">
                {{ this.getCreateAccessionDetails.accession_id }}
              </p>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "AccessionCard",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("orders", ["getCreateAccessionDetails"]),
  },
  mounted() {},
};
</script>
